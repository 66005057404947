import classes from "./AboutUs.module.css";

const small = require("../../Images/downtown.jpg");
const medium = require("../../Images/downtown.jpg");
const big = require("../../Images/downtown.jpg");

const AboutUs = () => {
  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <h1>About Us</h1>
        <hr className={classes.lineDiv} />
        <h3>
          <i>Crafting Excellence in Hotel Management</i>
        </h3>
        <p>
          Edison Hotel Operations is a dynamic hotel management company
          specializing in operating and overseeing a diverse portfolio of
          hospitality properties. With a focus on delivering exceptional guest
          experiences, the company provides comprehensive management services
          including operations, marketing, revenue management, and staff
          training.
          <br />
          <br />
          Edison Hotel Operations leverages its expertise to enhance property
          performance, ensuring each hotel under its management maintains high
          standards of service, profitability, and guest satisfaction. The
          company is committed to innovation, operational excellence, and
          fostering long-term partnerships within the hospitality industry.
        </p>
      </div>
      <div className={classes.imgContainer}>
        <picture>
          <source media="(max-width:700px)" srcset={small} />
          <source
            media="(max-width:1100px) and (min-width:700px)"
            srcset={medium}
          />

          <source media="(min-width: 1100px)" srcSet={big} />
          <img src={big} alt="Flowers" crossOrigin="anonymous" />
        </picture>
      </div>
    </div>
  );
};

export default AboutUs;
