import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Leaders from "./Components/Leaders/Leaders";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Home />
      <AboutUs />
      <Leaders />
      <Footer />
    </div>
  );
}

export default App;
