import classes from "./Home.module.css";

const Home = () => {
  return (
    <div className={classes.container}>
      <div className={classes.nameContainer}>
        <img
          src={require("../../Images/sheratonhoteloperationfinal.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default Home;
