import classes from "./Leaders.module.css";
import Member from "./Card/Member";

const Leaders = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h1> Our Team</h1>
        <hr className={classes.lineDiv} />
      </div>
      <div className={classes.body}>
        <div className={classes.box}>
          <Member
            big={require("../../Images/member1.jpg")}
            medium={require("../../Images/member1.jpg")}
            small={require("../../Images/member1.jpg")}
            name="Nancy Crivello"
            title="VP- SALES & MARKETING"
            bio="Nancy Crivello, is a seasoned sales professional started working in 1991 as a Director of Catering, became Director of Sales & Marketing in 2004, worked with Marriott, Hilton and many renowned brands. Nancy then with joined Edison Hotel Operations as Vice President of Sales & Marketing with an experience of over 30 years in the market. Her attitude towards handling complex business, sales growth & catering growth challenges in a competitive market landscape has done wonders. She has boosted growth trajectory through powerful marketing and sales strategies. She has secured high value projects & drove business integrations & transitions to elevate the overall revenue of the property."
          />
          <Member
            big={require("../../Images/member2.jpg")}
            medium={require("../../Images/member2.jpg")}
            small={require("../../Images/member2.jpg")}
            name="Bhavnish Purohit"
            title="VP-FINANCE"
            bio="Bhavnish Purohit is a seasoned finance professional started working in 2005 with Marriott. His knowledge of the business and his involvement in daily hotel operations clearly gave him the edge in hospitality industry. He joined Edison Hotel operations as a Vice President of Finance in 2019 is a true finance leader, a great hotelier and a thorough gentleman. With full knowledge of all Marriott systems, Micros, Opera, Lightspeed, M3, Savie, he can really make worthwhile recommendations that help meet both financial as well as quality goals, reflecting a strong career trajectory and outstanding performance. His intelligence, passion, knowledge in the finance domain is quite broad and that helps when trying to analyze numbers and information."
          />
          <Member
            big={require("../../Images/member3.jpg")}
            medium={require("../../Images/member3.jpg")}
            small={require("../../Images/member3.jpg")}
            name="Sakshi Jaiswal"
          />
        </div>
        <div className={classes.box}>
          <Member
            big={require("../../Images/photo4.jpeg")}
            medium={require("../../Images/photo2.jpeg")}
            small={require("../../Images/photo3.jpeg")}
            name="Keith Krauser"
            title="VP- ENGINEERING"
            bio="Keith Krauser, joined Edison hotel operations in 2022, has accomplished Engineering and Project Management professional with over 13 years of experience in multiple buildings. Currently serving as Vice President of Engineering, with a proven track record in optimizing facility operations, leading multidisciplinary teams, and managing complex projects from inception to commercial production. Recognized for achieving significant cost reductions, enhancing operational efficiency, and ensuring regulatory compliance. Experienced in managing plants with zero production loss during long term agreement and ensuring resource availability with best practices. Strong leader with a track record of developing team members, promoted from operational roles to managerial positions."
          />
          <Member
            big={require("../../Images/photo5.jpeg")}
            medium={require("../../Images/photo2.jpeg")}
            small={require("../../Images/photo3.jpeg")}
            name="Name5"
          />
        </div>
      </div>
    </div>
  );
};

export default Leaders;
