import ContactForm from "./ContactForm/ContactForm";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.formText}>
          <h1 className={classes.formTextHeading}>Edison Hotel Operations</h1>
          <div className={classes.lineBreaker}></div>
          <p className={classes.formTextContent}>
            If you have any questions or need further information, feel free to
            reach out. Our team is ready to assist you with any queries you may
            have. Simply contact via the attached form, and we'll get back to
            you promptly.
          </p>
        </div>
        <div className={classes.formPart}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Footer;
