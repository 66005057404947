import { useState } from "react";

import classes from "./Member.module.css";

const Member = (props) => {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const togglePopUp = () => {
    setIsPopUpVisible(!isPopUpVisible);
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer} onClick={togglePopUp}>
        <h1>{props.name}</h1>
        <picture>
          <source media="(max-width:700px)" srcset={props.small} />
          <source
            media="(max-width:1100px) and (min-width:700px)"
            srcset={props.medium}
          />

          <source media="(min-width: 1100px)" srcSet={props.big} />
          <img src={props.big} alt="Flowers" crossOrigin="anonymous" />
        </picture>
      </div>
      {isPopUpVisible && (
        <div className={classes.popupOverlay}>
          <div className={classes.popupContent}>
            <span className={classes.closer} onClick={togglePopUp}>
              &times;
            </span>
            <div className={classes.pImage}>
              <picture>
                <source media="(max-width:700px)" srcset={props.small} />
                <source
                  media="(max-width:1100px) and (min-width:700px)"
                  srcset={props.medium}
                />

                <source media="(min-width: 1100px)" srcSet={props.big} />
                <img src={props.big} alt="Flowers" crossOrigin="anonymous" />
              </picture>
            </div>
            <div className={classes.pText}>
              <h1>{props.name}</h1>
              <h2>{props.title}</h2>
              <p>{props.bio}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Member;
