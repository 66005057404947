import classes from "./NavBar.module.css";

const NavBar = () => {
  return (
    <div className={classes.container}>
      <h1>Edison Hotel Operations</h1>
    </div>
  );
};

export default NavBar;
